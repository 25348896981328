import $ from 'jquery'

import { onPageLoad } from '~/common/extensions/dom_ready'

let modifier, prepareClipboardText, showTooltip

showTooltip = function (elm, msg) {
  let $elm, $icon, org_icon, org_msg
  $elm = $(elm)
  org_msg = $elm.attr('data-original-title')
  $elm
    .attr('data-original-title', msg)
    .attr('aria-label', msg)
    .attr('data-placement', 'bottom')
  if ($elm.tooltip) {
    $elm.tooltip('show')
  } else {
    $elm[0].dispatchEvent(
      new CustomEvent('updateContent', { detail: { content: msg, show: true } })
    )
  }
  $elm.attr('data-original-title', org_msg)
  if ($elm.data('copiedIcon')) {
    $icon = $elm.find('i')
    org_icon = $icon[0]
    $icon.replaceWith($elm.data('copiedIcon'))
    return setTimeout(function () {
      if (!$elm.tooltip) {
        $elm[0].dispatchEvent(
          new CustomEvent('updateContent', {
            detail: { content: $elm.attr('data-copy'), show: false },
          })
        )
      }
      return $elm.find('i').replaceWith(org_icon)
    }, 2000)
  }
}

modifier = /Mac/i.test(navigator.userAgent) ? '⌘' : 'Ctrl'

// prepares clipboard text - useful in case composite fields
prepareClipboardText = function (trigger) {
  let txt
  trigger = $(trigger)
  if (trigger.data('clipboard-selectors')) {
    txt = ''
    trigger
      .data('clipboard-selectors')
      .split(',')
      .forEach(function (selector) {
        let el
        el = $(selector)
        if (el.data('clipboard-text')) {
          return (txt += el.data('clipboard-text'))
        } else if (
          ['INPUT', 'TEXTAREA', 'SELECT'].indexOf(el.prop('nodeName')) !== -1
        ) {
          return (txt += el.val())
        } else {
          return (txt +=
            trigger.data('clipboard-format') === 'html' ? el.html() : el.text())
        }
      })
  } else {
    txt = trigger.data('clipboard-text')
  }
  return txt
}

onPageLoad('clipboard', function () {
  if (/iPhone|iPad/i.test(navigator.userAgent)) {
    return $(document).on(
      'click',
      '*[data-clipboard-text], *[data-clipboard-selectors]',
      function () {
        return prompt(
          "Long-touch the text below, hit 'Select All', then 'Copy', and 'OK'",
          prepareClipboardText(this)
        )
      }
    )
  } else {
    return $(document).on(
      'click',
      '*[data-clipboard-text], *[data-clipboard-selectors]',
      function (e) {
        e.preventDefault()
        e.stopImmediatePropagation()
        const element = this
        const text = prepareClipboardText(element)
        navigator.clipboard.writeText(text).then(
          function () {
            window.getSelection().removeAllRanges()
            return showTooltip(element, $(element).data('copied'))
          },
          function (_err) {
            return showTooltip(
              element,
              `Press ${modifier}-${element.action === 'cut' ? 'X' : 'C'} to ${
                element.action
              }`
            )
          }
        )
      }
    )
  }
})
